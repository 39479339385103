<template>
    <div class="form-image-upload">
        <input
            ref="btn-image-upload"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            @change="handle.convertToBase64"
            hidden
        />
        <a @click="$refs['btn-image-upload'].click()" class="btn btn-dark cursor-pointer">
            <i class="flaticon2-image-file mr-2"></i> เลือกจากที่เก็บไฟล์
        </a>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

//* plugins
import { get, set } from 'lodash';
import { image, tooltify } from '@/assets/js/tooltify';

export default {
    name: 'module-image-upload',
    components: {},
    props: {},
    setup(props, Vue) {
        const data = reactive({});

        const handle = {
            convertToBase64: (e) => {
                let file = e.target.files[0];

                if (file !== undefined) {
                    if (file.type.indexOf('image/') === -1) {
                        tooltify.popup({
                            title: 'แจ้งเตือน',
                            type: 'error',
                            text: 'เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ',
                            timer: '3000',
                        });

                        return;
                    }
                    if (typeof FileReader === 'function') {
                        let reader = new FileReader();

                        reader.onload = (event) => {
                            image.resize(event.target.result, 200, 200).then((resize) => {
                                Vue.emit('uploaded', resize);
                            });
                        };

                        reader.readAsDataURL(file);
                    } else {
                        tooltify.popup({
                            title: 'แจ้งเตือน',
                            type: 'error',
                            text: 'เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ',
                            timer: '3000',
                        });
                    }
                }
            },
        };

        return { data, props, handle, get, set };
    },
};
</script>

<style lang="scss"></style>
