var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-in-left" } }, [
    _c(
      "div",
      { staticClass: "main-panel" },
      [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header card-header-danger" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("แบบฟอร์มคำนวนเบี้ยประกัน"),
                    ]),
                    _c("p", { staticClass: "card-category header-credit-at" }, [
                      _c("label", [_vm._v("พันธมิตร151")]),
                      _vm._v(
                        " โดย ศักดิ์สิทธิ์ เตินเตียน\n                                "
                      ),
                    ]),
                    _vm.data.route == "dashboard"
                      ? _c(
                          "label",
                          {
                            staticClass: "header-btn-login",
                            on: {
                              click: function ($event) {
                                return _vm.handle.login()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-user-lock" })]
                        )
                      : _c(
                          "label",
                          {
                            staticClass: "header-btn-back",
                            on: {
                              click: function ($event) {
                                return _vm.handle.back()
                              },
                            },
                          },
                          [_c("i", { staticClass: "flaticon2-back" })]
                        ),
                  ]),
                  _c("div", { staticClass: "card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "container-fluid" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "header-btn-back-mobile d-none",
                            on: {
                              click: function ($event) {
                                return _vm.handle.back()
                              },
                            },
                          },
                          [_c("i", { staticClass: "flaticon2-back" })]
                        ),
                        _c("router-view"),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("layouts-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }