<template>
    <div class="layout-footer">
        <modal @opened="handle.formLogin()" name="modal-login" width="500" title="เข้าสู่ระบบ">
            <div class="modal-body">
                <div class="col-lg-12">
                    <form class="form" novalidate="novalidate" id="form-login">
                        <div class="form-group">
                            <label>รหัสตัวแทน</label>
                            <input
                                ref="username"
                                name="username"
                                type="text"
                                class="form-control"
                                placeholder="รหัสตัวแทน"
                            />
                        </div>
                        <div class="form-group">
                            <label>รหัสผ่าน</label>
                            <input
                                @focus="$event.target.select()"
                                name="password"
                                type="password"
                                class="form-control"
                                placeholder="รหัสผ่านเข้าระบบ"
                            />
                        </div>
                        <button type="submit" class="btn btn-danger mr-2">เข้าสู่ระบบ</button>
                    </form>
                </div>
            </div>
        </modal>

        <modal @opened="handle.formProfile()" name="modal-profile" width="500" title="ข้อมูลผู้ใช้">
            <div class="modal-body">
                <div class="col-lg-12">
                    <form class="form" novalidate="novalidate" id="form-profile">
                        <div class="form-group text-center my-3">
                            <div class="image-profile" :style="'background-image:url(' + data.form.avatar + ')'"></div>
                        </div>
                        <hr />
                        <div class="form-group">
                            <label>อัพโหลดรูปโปรไฟล์</label>
                            <image-upload @uploaded="(e) => (data.form.avatar = e)" />
                        </div>
                        <hr />
                        <div class="form-group">
                            <label>รหัสตัวแทน</label>
                            <input
                                :value="data.form.username"
                                ref="username"
                                name="username"
                                type="text"
                                class="form-control"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label>ชื่อผู้ใช้</label>
                            <input
                                v-model="data.form.name"
                                ref="name"
                                name="name"
                                type="text"
                                class="form-control"
                                placeholder="ชื่อผู้ใช้"
                            />
                        </div>
                        <div class="form-group">
                            <label>เบอร์โทรศัพท์</label>
                            <input
                                v-model="data.form.phone"
                                ref="phone"
                                name="phone"
                                type="number"
                                class="form-control"
                                placeholder="เบอร์โทรศัพท์"
                            />
                        </div>
                        <div class="form-group">
                            <label>รหัสผ่าน</label>
                            <input
                                v-model="data.form.password"
                                @focus="$event.target.select()"
                                name="password"
                                type="password"
                                class="form-control"
                                placeholder="รหัสผ่านเข้าระบบ"
                            />
                        </div>
                        <button type="submit" class="btn btn-danger mr-2">แก้ไขข้อมูล</button>
                        <button @click="handle.logout" type="button" class="btn btn-light float-right">
                            ออกจากระบบ
                        </button>
                    </form>
                </div>
            </div>
        </modal>

        <modal @opened="handle.formUsers()" name="modal-users" width="800" title="รายละเอียดผู้ใช้">
            <div class="modal-body">
                <div class="col-lg-12 my-3">
                    <div class="btn-group" role="group">
                        <button @click="handle.modalUserUpdate()" type="button" class="btn btn-secondary">
                            เพิ่มรหัสตัวแทน
                        </button>
                    </div>
                </div>
                <div class="col-lg-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">รหัสตัวแทน</th>
                                <th scope="col">ชื่อ</th>
                                <th class="text-center" scope="col">active</th>
                                <th width="60" scope="col">จัดการ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(user, key) in get(store, 'users')">
                                <tr :key="key">
                                    <th scope="row">{{ user.username }}</th>
                                    <td>{{ user.name }}</td>
                                    <td
                                        class="text-center"
                                        :class="user.active == '1' ? 'text-success' : 'text-danger'"
                                    >
                                        {{ user.active == '1' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
                                    </td>
                                    <td class="text-right p-1">
                                        <div class="btn-group" role="group">
                                            <button
                                                v-if="user.uuid == '0KxFSbZiDf6TDuvjLld3'"
                                                type="button"
                                                disabled
                                                class="btn btn-danger"
                                            >
                                                ลบ
                                            </button>
                                            <button
                                                v-else
                                                @click="handle.formUserRemove(user.uuid)"
                                                type="button"
                                                class="btn btn-danger"
                                            >
                                                ลบ
                                            </button>

                                            <button
                                                @click="handle.modalUserUpdate(user.uuid)"
                                                type="button"
                                                class="btn btn-light"
                                            >
                                                แก้ไข
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>

        <modal @opened="handle.formUserUpdate()" name="modal-user-update" width="500" title="จัดการตัวแทน">
            <div class="modal-body">
                <div class="col-lg-12">
                    <form class="form" novalidate="novalidate" id="form-user-update">
                        <div class="form-group text-center my-3">
                            <div
                                class="image-profile"
                                :style="'background-image:url(' + data.form.userUpdate.avatar + ')'"
                            ></div>
                        </div>
                        <hr />
                        <div class="form-group">
                            <label>อัพโหลดรูปโปรไฟล์</label>
                            <image-upload @uploaded="(e) => (data.form.userUpdate.avatar = e)" />
                        </div>
                        <hr />
                        <div class="form-group">
                            <label>รหัสตัวแทน</label>
                            <input
                                v-model="data.form.userUpdate.username"
                                ref="username"
                                name="username"
                                type="text"
                                class="form-control"
                            />
                        </div>
                        <div class="form-group">
                            <label>เปิดใช้งาน</label>
                            <br />
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    v-model="data.form.userUpdate.active"
                                    type="radio"
                                    id="active1"
                                    name="active"
                                    ref="active"
                                    :value="1"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="active1">เปิดใช้งาน</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    v-model="data.form.userUpdate.active"
                                    type="radio"
                                    id="active2"
                                    name="active"
                                    ref="active"
                                    :value="0"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="active2">ปิดใช้งาน</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>ชื่อผู้ใช้</label>
                            <input
                                v-model="data.form.userUpdate.name"
                                ref="name"
                                name="name"
                                type="text"
                                class="form-control"
                                placeholder="ชื่อผู้ใช้"
                            />
                        </div>
                        <div class="form-group">
                            <label>เบอร์โทรศัพท์</label>
                            <input
                                v-model="data.form.userUpdate.phone"
                                ref="phone"
                                name="phone"
                                type="number"
                                class="form-control"
                                placeholder="เบอร์โทรศัพท์"
                            />
                        </div>
                        <div class="form-group">
                            <label>รหัสผ่าน</label>
                            <input
                                v-model="data.form.userUpdate.password"
                                @focus="$event.target.select()"
                                name="password"
                                type="password"
                                class="form-control"
                                placeholder="รหัสผ่านเข้าระบบ"
                            />
                        </div>
                        <button type="submit" class="btn btn-danger mr-2">
                            {{ get(data, 'form.userUpdate.uuid') ? 'แก้ไขข้อมูล' : 'เพิ่มตัวแทน' }}
                        </button>
                    </form>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { reactive, watch, onBeforeMount } from '@vue/composition-api';

//* store
import { USER_UPDATE_DISPATCH, USER_REMOVE_DISPATCH, USER_LOGOUT_DISPATCH, USERS_DISPATCH } from '@/store/account/user';
import { USER_LOGIN_DISPATCH } from '@/store/account/user';

//* plugins
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { route, sha256, state, tooltify, validation } from '@/assets/js/tooltify';

//* components
import modal from '@/components/modules/modal.module.vue';
import imageUpload from '@/components/modules/image.upload.module.vue';

export default {
    name: 'layouts',
    components: { modal, imageUpload },
    setup(props, Vue) {
        const store = reactive({
            isAuthen: state.getters('USER_AUTHEN_GETTERS'),
            user: state.getters('USER_GETTERS'),
            users: state.getters('USERS_GETTERS'),
            configs: state.getters('CONFIGS_GETTERS'),
        });

        const data = reactive({
            route: Vue.root.$route.name,
            form: {
                uuid: undefined,
                name: store.user.name,
                phone: store.user.phone,
                username: store.user.username,
                password: store.user.password,
                avatar: get(store, 'user.avatar', '/media/image/avatar/no-image.png'),
                userUpdate: {
                    uuid: undefined,
                    name: '',
                    phone: '',
                    active: 1,
                    username: '',
                    password: '',
                    avatar: '/media/image/avatar/no-image.png',
                },
            },
            to: 'dashboard',
        });

        onBeforeMount(() => {
            isAuthen();
        });

        watch(
            () => store.isAuthen,
            () => {
                isAuthen();
            },
        );
        watch(
            () => store.user,
            (user) => {
                set(data, 'form.uuid', user.uuid);
                set(data, 'form.name', user.name);
                set(data, 'form.phone', user.phone);
                set(data, 'form.username', user.username);
                set(data, 'form.password', user.password);
                set(data, 'form.avatar', get(user, 'avatar', '/media/image/avatar/no-image.png'));
            },
        );

        watch(
            () => Vue.root.$route.name,
            (name) => {
                set(data, 'route', name);
                isAuthen();
            },
        );

        const isAuthen = () => {
            let route = Vue.root.$route.name;

            if (store.isAuthen != undefined && store.isAuthen == false) {
                if (route != 'dashboard' && route != 'query') {
                    Vue.root.$router.push({ name: 'dashboard' }).catch(() => {});
                }
            }
        };

        const handle = {
            formLogin: () => {
                validation.form(
                    'form-login',
                    {
                        username: 'required|regex:A0|min:4',
                        password: 'required|regex:A0|min:4',
                    },
                    (form) => {
                        if (get(form, 'status')) {
                            state
                                .dispatch(USER_LOGIN_DISPATCH, {
                                    value: {
                                        username: get(form, 'value.username'),
                                        password: sha256(get(form, 'value.password')),
                                    },
                                })
                                .then(() => {
                                    Vue.root.$modal.hide('modal-login');

                                    route(Vue, store.configs.routeTo);
                                })
                                .catch((err) => {
                                    if (err == 'username') {
                                        validation.errorAdd('form-login', 'username', 'รหัสตัวแทนไม่ถูกต้อง');
                                        validation.errorAdd('form-login', 'password', 'หรือรหัสผ่านไม่ถูกต้อง');
                                    }
                                    if (err == 'block') {
                                        validation.errorAdd('form-login', 'username', 'บัญชีดังกล่าวถูกบล็อกการใช้งาน');
                                    }
                                });
                        }
                    },
                );
            },
            formProfile: () => {
                validation.form(
                    'form-profile',
                    {
                        name: 'required|min:4',
                        phone: 'required|min:9',
                        password: 'required|regex:A0|min:4',
                    },
                    (form) => {
                        if (get(form, 'status')) {
                            tooltify.confirm(
                                {
                                    title: 'การยืนยันข้อมูล',
                                    text: 'แก้ไขข้อมูลผู้ใช้นี้ ใช่หรือไม่ ?',
                                    type: 'question',
                                    cancel: 'ยกเลิก',
                                    btn: 'ยืนยัน',
                                },
                                (confirm) => {
                                    if (confirm) {
                                        let password;

                                        if (get(data, 'form.password') == get(store, 'user.password')) {
                                            password = get(data, 'form.password');
                                        } else {
                                            password = sha256(get(data, 'form.password'));
                                        }

                                        state
                                            .dispatch(USER_UPDATE_DISPATCH, {
                                                value: {
                                                    name: get(data, 'form.name'),
                                                    phone: get(data, 'form.phone'),
                                                    username: get(data, 'form.username'),
                                                    avatar: get(data, 'form.avatar'),
                                                    password: password,
                                                },
                                                uuid: get(data, 'form.uuid'),
                                            })
                                            .then(() => {
                                                Vue.root.$modal.hide('modal-profile');

                                                tooltify.popup({
                                                    title: 'แจ้งเตือน',
                                                    type: 'success',
                                                    text: 'ทำรายการสำเร็จแล้ว...',
                                                    timer: '3000',
                                                });
                                            });
                                    }
                                },
                            );
                        }
                    },
                );
            },
            modalUserUpdate: (uuid) => {
                set(data, 'form.userUpdate', {
                    uuid: uuid,
                    name: get(store.users[uuid], 'name', ''),
                    active: get(store.users[uuid], 'active', 1),
                    phone: get(store.users[uuid], 'phone', ''),
                    username: get(store.users[uuid], 'username', ''),
                    password: get(store.users[uuid], 'password', ''),
                    avatar: get(store.users[uuid], 'avatar', '/media/image/avatar/no-image.png'),
                    passwordOld: cloneDeep(get(store.users[uuid], 'password', '')),
                });

                Vue.root.$modal.show('modal-user-update');
            },
            formUsers: () => {
                if (isEmpty(get(store, 'users'))) {
                    state.dispatch(USERS_DISPATCH);
                }
            },
            formUserUpdate: () => {
                validation.form(
                    'form-user-update',
                    {
                        name: 'required|min:4',
                        phone: 'required|min:9',
                        username: 'required|regex:A0|min:4',
                        password: 'required|regex:A0|min:4',
                    },
                    (form) => {
                        if (get(form, 'status')) {
                            tooltify.confirm(
                                {
                                    title: 'การยืนยันข้อมูล',
                                    text: get(data, 'form.userUpdate.uuid')
                                        ? 'แก้ไขข้อมูลผู้ใช้นี้ ใช่หรือไม่ ?'
                                        : 'เพิ่มข้อมูลผู้ใช้นี้ ใช่หรือไม่ ?',
                                    type: 'question',
                                    cancel: 'ยกเลิก',
                                    btn: 'ยืนยัน',
                                },
                                (confirm) => {
                                    if (confirm) {
                                        let password;
                                        if (
                                            get(data, 'form.userUpdate.password') ==
                                            get(data, 'form.userUpdate.passwordOld')
                                        ) {
                                            password = get(data, 'form.userUpdate.passwordOld');
                                        } else {
                                            password = sha256(get(data, 'form.userUpdate.password'));
                                        }

                                        state
                                            .dispatch(USER_UPDATE_DISPATCH, {
                                                uuid: get(data, 'form.userUpdate.uuid'),
                                                value: {
                                                    name: get(data, 'form.userUpdate.name'),
                                                    username: get(data, 'form.userUpdate.username'),
                                                    phone: get(data, 'form.userUpdate.phone'),
                                                    active: get(data, 'form.userUpdate.active'),
                                                    avatar: get(data, 'form.userUpdate.avatar'),
                                                    password: password,
                                                },
                                            })
                                            .then(() => {
                                                Vue.root.$modal.hide('modal-user-update');

                                                tooltify.popup({
                                                    title: 'แจ้งเตือน',
                                                    type: 'success',
                                                    text: 'ทำรายการสำเร็จแล้ว...',
                                                    timer: '3000',
                                                });
                                            });
                                    }
                                },
                            );
                        }
                    },
                );
            },
            formUserRemove: (userId) => {
                tooltify.confirm(
                    {
                        title: 'การยืนยันข้อมูล',
                        text: 'ลบรหัสตัวแทนนี้ ใช่หรือไม่ ?',
                        type: 'question',
                        cancel: 'ยกเลิก',
                        btn: 'ลบรหัสตัวแทน',
                    },
                    (confirm) => {
                        if (confirm) {
                            state.dispatch(USER_REMOVE_DISPATCH, userId).then(() => {
                                tooltify.popup({
                                    title: 'แจ้งเตือน',
                                    type: 'success',
                                    text: 'ทำรายการสำเร็จแล้ว...',
                                    timer: '3000',
                                });
                            });
                        }
                    },
                );
            },
            logout: () => {
                tooltify.confirm(
                    {
                        title: 'การยืนยันข้อมูล',
                        text: 'คุณต้องออกจากระบบ ใช่หรือไม่ ?',
                        type: 'question',
                        cancel: 'ยกเลิก',
                        btn: 'ออกจากระบบ',
                    },
                    (confirm) => {
                        if (confirm) {
                            state.dispatch(USER_LOGOUT_DISPATCH);
                            Vue.root.$modal.hide('modal-profile');
                        }
                    },
                );
            },
        };

        return { store, data, handle, get };
    },
};
</script>

<style lang="scss">
.image-profile {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: inherit;
    background-position: center;
    display: inline-flex;
}
</style>
