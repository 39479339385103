var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-footer" },
    [
      _c(
        "modal",
        {
          attrs: { name: "modal-login", width: "500", title: "เข้าสู่ระบบ" },
          on: {
            opened: function ($event) {
              return _vm.handle.formLogin()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "form",
                {
                  staticClass: "form",
                  attrs: { novalidate: "novalidate", id: "form-login" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสตัวแทน")]),
                    _c("input", {
                      ref: "username",
                      staticClass: "form-control",
                      attrs: {
                        name: "username",
                        type: "text",
                        placeholder: "รหัสตัวแทน",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสผ่าน")]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        name: "password",
                        type: "password",
                        placeholder: "รหัสผ่านเข้าระบบ",
                      },
                      on: {
                        focus: function ($event) {
                          return $event.target.select()
                        },
                      },
                    }),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mr-2",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("เข้าสู่ระบบ")]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: { name: "modal-profile", width: "500", title: "ข้อมูลผู้ใช้" },
          on: {
            opened: function ($event) {
              return _vm.handle.formProfile()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "form",
                {
                  staticClass: "form",
                  attrs: { novalidate: "novalidate", id: "form-profile" },
                },
                [
                  _c("div", { staticClass: "form-group text-center my-3" }, [
                    _c("div", {
                      staticClass: "image-profile",
                      style:
                        "background-image:url(" + _vm.data.form.avatar + ")",
                    }),
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("อัพโหลดรูปโปรไฟล์")]),
                      _c("image-upload", {
                        on: {
                          uploaded: function (e) {
                            return (_vm.data.form.avatar = e)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสตัวแทน")]),
                    _c("input", {
                      ref: "username",
                      staticClass: "form-control",
                      attrs: { name: "username", type: "text", readonly: "" },
                      domProps: { value: _vm.data.form.username },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("ชื่อผู้ใช้")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.name,
                          expression: "data.form.name",
                        },
                      ],
                      ref: "name",
                      staticClass: "form-control",
                      attrs: {
                        name: "name",
                        type: "text",
                        placeholder: "ชื่อผู้ใช้",
                      },
                      domProps: { value: _vm.data.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data.form, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("เบอร์โทรศัพท์")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.phone,
                          expression: "data.form.phone",
                        },
                      ],
                      ref: "phone",
                      staticClass: "form-control",
                      attrs: {
                        name: "phone",
                        type: "number",
                        placeholder: "เบอร์โทรศัพท์",
                      },
                      domProps: { value: _vm.data.form.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data.form, "phone", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสผ่าน")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.password,
                          expression: "data.form.password",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "password",
                        type: "password",
                        placeholder: "รหัสผ่านเข้าระบบ",
                      },
                      domProps: { value: _vm.data.form.password },
                      on: {
                        focus: function ($event) {
                          return $event.target.select()
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data.form,
                            "password",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mr-2",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("แก้ไขข้อมูล")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light float-right",
                      attrs: { type: "button" },
                      on: { click: _vm.handle.logout },
                    },
                    [
                      _vm._v(
                        "\n                        ออกจากระบบ\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "modal-users",
            width: "800",
            title: "รายละเอียดผู้ใช้",
          },
          on: {
            opened: function ($event) {
              return _vm.handle.formUsers()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-lg-12 my-3" }, [
              _c(
                "div",
                { staticClass: "btn-group", attrs: { role: "group" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.handle.modalUserUpdate()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        เพิ่มรหัสตัวแทน\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-lg-12" }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v("รหัสตัวแทน"),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v("ชื่อ")]),
                    _c(
                      "th",
                      { staticClass: "text-center", attrs: { scope: "col" } },
                      [_vm._v("active")]
                    ),
                    _c("th", { attrs: { width: "60", scope: "col" } }, [
                      _vm._v("จัดการ"),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.get(_vm.store, "users"), function (user, key) {
                      return [
                        _c("tr", { key: key }, [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(_vm._s(user.username)),
                          ]),
                          _c("td", [_vm._v(_vm._s(user.name))]),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              class:
                                user.active == "1"
                                  ? "text-success"
                                  : "text-danger",
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    user.active == "1"
                                      ? "เปิดใช้งาน"
                                      : "ปิดใช้งาน"
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _c("td", { staticClass: "text-right p-1" }, [
                            _c(
                              "div",
                              {
                                staticClass: "btn-group",
                                attrs: { role: "group" },
                              },
                              [
                                user.uuid == "0KxFSbZiDf6TDuvjLld3"
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button", disabled: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            ลบ\n                                        "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handle.formUserRemove(
                                              user.uuid
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            ลบ\n                                        "
                                        ),
                                      ]
                                    ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-light",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handle.modalUserUpdate(
                                          user.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            แก้ไข\n                                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "modal-user-update",
            width: "500",
            title: "จัดการตัวแทน",
          },
          on: {
            opened: function ($event) {
              return _vm.handle.formUserUpdate()
            },
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "form",
                {
                  staticClass: "form",
                  attrs: { novalidate: "novalidate", id: "form-user-update" },
                },
                [
                  _c("div", { staticClass: "form-group text-center my-3" }, [
                    _c("div", {
                      staticClass: "image-profile",
                      style:
                        "background-image:url(" +
                        _vm.data.form.userUpdate.avatar +
                        ")",
                    }),
                  ]),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("อัพโหลดรูปโปรไฟล์")]),
                      _c("image-upload", {
                        on: {
                          uploaded: function (e) {
                            return (_vm.data.form.userUpdate.avatar = e)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสตัวแทน")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.userUpdate.username,
                          expression: "data.form.userUpdate.username",
                        },
                      ],
                      ref: "username",
                      staticClass: "form-control",
                      attrs: { name: "username", type: "text" },
                      domProps: { value: _vm.data.form.userUpdate.username },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data.form.userUpdate,
                            "username",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("เปิดใช้งาน")]),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-radio custom-control-inline",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.form.userUpdate.active,
                              expression: "data.form.userUpdate.active",
                            },
                          ],
                          ref: "active",
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "active1",
                            name: "active",
                          },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.data.form.userUpdate.active, 1),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.data.form.userUpdate,
                                "active",
                                1
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "active1" },
                          },
                          [_vm._v("เปิดใช้งาน")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "custom-control custom-radio custom-control-inline",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.form.userUpdate.active,
                              expression: "data.form.userUpdate.active",
                            },
                          ],
                          ref: "active",
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "radio",
                            id: "active2",
                            name: "active",
                          },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.data.form.userUpdate.active, 0),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.data.form.userUpdate,
                                "active",
                                0
                              )
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "active2" },
                          },
                          [_vm._v("ปิดใช้งาน")]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("ชื่อผู้ใช้")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.userUpdate.name,
                          expression: "data.form.userUpdate.name",
                        },
                      ],
                      ref: "name",
                      staticClass: "form-control",
                      attrs: {
                        name: "name",
                        type: "text",
                        placeholder: "ชื่อผู้ใช้",
                      },
                      domProps: { value: _vm.data.form.userUpdate.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data.form.userUpdate,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("เบอร์โทรศัพท์")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.userUpdate.phone,
                          expression: "data.form.userUpdate.phone",
                        },
                      ],
                      ref: "phone",
                      staticClass: "form-control",
                      attrs: {
                        name: "phone",
                        type: "number",
                        placeholder: "เบอร์โทรศัพท์",
                      },
                      domProps: { value: _vm.data.form.userUpdate.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data.form.userUpdate,
                            "phone",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("รหัสผ่าน")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.form.userUpdate.password,
                          expression: "data.form.userUpdate.password",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "password",
                        type: "password",
                        placeholder: "รหัสผ่านเข้าระบบ",
                      },
                      domProps: { value: _vm.data.form.userUpdate.password },
                      on: {
                        focus: function ($event) {
                          return $event.target.select()
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data.form.userUpdate,
                            "password",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger mr-2",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.get(_vm.data, "form.userUpdate.uuid")
                              ? "แก้ไขข้อมูล"
                              : "เพิ่มตัวแทน"
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }