var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-image-upload" }, [
    _c("input", {
      ref: "btn-image-upload",
      attrs: {
        type: "file",
        accept: "image/x-png,image/gif,image/jpeg",
        hidden: "",
      },
      on: { change: _vm.handle.convertToBase64 },
    }),
    _c(
      "a",
      {
        staticClass: "btn btn-dark cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.$refs["btn-image-upload"].click()
          },
        },
      },
      [
        _c("i", { staticClass: "flaticon2-image-file mr-2" }),
        _vm._v(" เลือกจากที่เก็บไฟล์\n    "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }