<template>
    <transition name="fade-in-left">
        <div class="main-panel">
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header card-header-danger">
                                    <!-- <img src="media/logo/aia.white.png" /> -->
                                    <h4 class="card-title">แบบฟอร์มคำนวนเบี้ยประกัน</h4>
                                    <p class="card-category header-credit-at">
                                        <label>พันธมิตร151</label> โดย ศักดิ์สิทธิ์ เตินเตียน
                                    </p>
                                    <label
                                        @click="handle.login()"
                                        v-if="data.route == 'dashboard'"
                                        class="header-btn-login"
                                    >
                                        <i class="fas fa-user-lock"></i>
                                    </label>
                                    <label @click="handle.back()" v-else class="header-btn-back">
                                        <i class="flaticon2-back"></i>
                                    </label>
                                </div>
                                <div class="card-footer">
                                    <div class="container-fluid">
                                        <label @click="handle.back()" class="header-btn-back-mobile d-none">
                                            <i class="flaticon2-back"></i>
                                        </label>
                                        <router-view></router-view>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <layouts-footer />
        </div>
    </transition>
</template>

<script>
import { reactive, watch, onBeforeMount } from '@vue/composition-api';

//* plugins
import { state } from '@/assets/js/tooltify';
import { set } from 'lodash';

//* components
import layoutsFooter from '@/view/layouts/layouts.footer.vue';

export default {
    name: 'layouts',
    components: { layoutsFooter },
    setup(props, Vue) {
        const store = reactive({
            isAuthen: state.getters('USER_AUTHEN_GETTERS'),
        });

        const data = reactive({
            route: Vue.root.$route.name,
        });

        onBeforeMount(() => {
            isAuthen();
        });

        watch(
            () => store.isAuthen,
            () => {
                isAuthen();
            },
        );

        watch(
            () => Vue.root.$route.name,
            (name) => {
                set(data, 'route', name);
                isAuthen();
            },
        );

        const isAuthen = () => {
            let route = Vue.root.$route.name;

            if (store.isAuthen != undefined && store.isAuthen == false) {
                if (route != 'dashboard' && route != 'query') {
                    Vue.root.$router.push({ name: 'dashboard' }).catch(() => {});
                }
            }
        };

        const handle = {
            login: () => {
                if (store.isAuthen) {
                    Vue.root.$modal.show('modal-profile');
                } else {
                    Vue.root.$modal.show('modal-login');
                }
            },
            back: () => {
                Vue.root.$router.push({ name: 'dashboard' }).catch(() => {});
            },
        };

        return { store, data, handle };
    },
};
</script>
